export function Icon({
  height = "3.125rem",
  width = "3.125rem",
  color = "#0096FA",
  url,
  onPress = () => {},
  customClass = "",
}) {
  return (
    <div
      onClick={onPress}
      style={{
        cursor: onPress !== undefined ? "pointer" : "",
        backgroundColor: color,
        maskImage: `url(${url})`,
        WebkitMaskImage: `url(${url})`,
        maskRepeat: "no-repeat",
        WebkitMaskRepeat: "no-repeat",
        maskPosition: "center",
        WebkitMaskPosition: "center",
        height,
        width,
        WebkitMaskSize: `${width} ${height}`,
        maskSize: `${width} ${height}`,
      }}
      role="presentation"
      className={customClass}
    />
  );
}

Icon.displayName = "Icon";
