import { useEffect, useRef } from "react";

interface UseIntersectionObserverProps {
  callback: () => void; // The function to call when intersection is triggered
  cursor?: string | null; // Cursor to track changes for pagination
  rootMargin?: string; // Optional margin around root
  threshold?: number; // Optional threshold for triggering
}

export const useIntersectionObserver = ({
  callback,
  cursor,
  rootMargin = "0px",
  threshold = 0.1,
}: UseIntersectionObserverProps) => {
  const observerRef = useRef<any>(null);

  useEffect(() => {
    if (!observerRef.current) return;

    const observer = new IntersectionObserver(
      ([entries]) => {
        if (entries!.isIntersecting && cursor) {
          callback();
        }
      },
      { rootMargin, threshold }
    );

    observer.observe(observerRef.current);

    // Cleanup observer when component unmounts or cursor changes
    return () => observer.disconnect();
  }, [callback, cursor, rootMargin, threshold]);

  return observerRef;
};
