import { useState } from "react";
import styled from "styled-components";
import DocSperaArrow from "../../assets/arrow.svg"; // Adjust path if needed

const DropdownWrapper = styled.div`
  position: relative;
  width: 100%;
  pointer-events: ${(props) => props.$disabled && "none"};
`;

const DropdownHeader = styled.div`
  background-color: #f3f3f3;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  padding: 0 5px;
  color: #0096fa;
`;

const Arrow = styled.img`
  width: 10px;
  height: 12px;
  transform: ${(props) => (props.$isOpen ? "rotate(-90deg)" : "rotate(90deg)")};
`;

const DropdownList = styled.ul`
  position: absolute;
  width: 100%;
  background-color: #f3f3f3;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: fit-content;
  overflow-y: auto;
  z-index: 10;
  border-radius: 2px;
  top: 19px;
  padding-top: 2px;
`;

const DropdownItem = styled.li`
  padding: 2px 5px;
  cursor: pointer;
  &:hover {
    background: #e0e0e0;
  }
`;

const TableDropdown = ({ options, onSelect, placeholder, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(placeholder);

  const toggleDropdown = () => setIsOpen(!isOpen);
  const handleSelect = (value) => {
    setSelected(value);
    onSelect(value);
    setIsOpen(false);
  };

  return (
    <DropdownWrapper $disabled={disabled}>
      <DropdownHeader onClick={toggleDropdown}>
        {selected ? (
          options.find((option) => option.value === selected)?.name
        ) : placeholder === null ? (
          <span style={{ height: "21px" }}></span>
        ) : placeholder === "" ? (
          "Select Value"
        ) : (
          placeholder
        )}
        <Arrow src={DocSperaArrow} alt="dropdown-arrow" $isOpen={isOpen} />
      </DropdownHeader>
      {isOpen && (
        <DropdownList>
          {options.map((option) => (
            <DropdownItem
              key={option.value}
              onClick={() => handleSelect(option.value)}
            >
              {option.name}
            </DropdownItem>
          ))}
        </DropdownList>
      )}
    </DropdownWrapper>
  );
};

export default TableDropdown;
