import React, { useEffect, useContext } from "react";
import { Context as MappingContext } from "../../context/MappingContext";
import MappingsHeading from "../../components/Heading/MappingsHeading";
import SurgeonMappingsTable from "../../components/SurgeonMappingsTable/SurgeonMappingsTable";
import LocationMappings from "../../components/LocationMappings/LocationMappings";
import DeleteMappingModal from "../../components/DeleteMappingModal/DeleteMappingModal";
import Modal from "../../components/CaseModal/Modal";

function Mappings({ isLocation }) {
  const {
    state: mappingState,
    toggleModal,
    setSelectedLocationMapping,
    toggleMapping,
  } = useContext(MappingContext);

  useEffect(() => {
    toggleMapping(isLocation);
  }, [isLocation]);

  const handleCloseModal = () => {
    toggleModal(false);
    setSelectedLocationMapping(null);
  };

  return (
    <div className="mt-4">
      <MappingsHeading />
      {mappingState.isLocation ? (
        <LocationMappings />
      ) : (
        <SurgeonMappingsTable />
      )}
      {mappingState.isModalOpen && (
        <Modal
          maxWidth="40%"
          content={<DeleteMappingModal />}
          toggleModal={() => handleCloseModal()}
          title={`Delete ${
            mappingState.isLocation ? "Location" : "Surgeon"
          } Mapping`}
        />
      )}
    </div>
  );
}

export default Mappings;
