import moment from "moment";

/* eslint-disable */
export const AlomEncodeType2 = (Number) => {
  if (typeof Number === "number" && Number % 1 === 0 && Number > 0) {
    var NumberArray = Number.toString().split("");
    // eslint-disable-next-line
    var SumOfNumber = eval(NumberArray.join("+"));
    var SumOfLength = Number.toString().length;
    while (SumOfNumber > 9) {
      var DigitNum = SumOfNumber.toString().split("");
      // eslint-disable-next-line
      SumOfNumber = eval(DigitNum.join("+"));
    }
    while (SumOfLength > 9) {
      var DigitLength = SumOfLength.toString().split("");
      // eslint-disable-next-line
      SumOfLength = eval(DigitLength.join("+"));
    }
    return Number + "" + SumOfLength + "" + SumOfNumber;
  } else {
    return false;
  }
};

export const AlomDecodeType2 = (encodedUserID) => {
  // Can we convert the input into an integer?
  if (!Number.isInteger(parseInt(encodedUserID))) {
    return 0;
  }

  // Removed last 2 chars from the right, and convert back to an integer.
  const strippedUserID = parseInt(encodedUserID.slice(0, -2));

  //Is the New Value a Positive Integer?
  if (strippedUserID > 0) {
    //Does the Original Value equal to the encoded form of the New Value.
    if (AlomEncodeType2(strippedUserID) === encodedUserID) {
      return strippedUserID;
    }
  }

  // default expects Metric (kg), converts to US Customary (lbs)
  const weightLbs = parseFloat(inputWeight) * 2.20462;
  const weightFinal = 0.5 * Math.floor(weightLbs * 2.0 + 0.5);
  return parseFloat(weightFinal.toFixed(2));
};

export const convertInchesToFtIn = (inputInches) => {
  let outputArray = ["", ""],
    newFt = parseInt(parseFloat(inputInches) / 12),
    newIn = Math.round(parseFloat(inputInches) % 12);

  if (newIn === 12) {
    newFt = newFt + 1;
    newIn = 0;
  }
  outputArray[0] = newFt;
  outputArray[1] = newIn;
  return outputArray;
};

// input height and weight must be in metric
export const calculateBMI = (height, weight) => {
  return parseFloat((weight / Math.pow(height, 2)) * 10000).toFixed(1);
};

export const formatEthnicity = (inputEthnicity) => {
  switch (inputEthnicity) {
    case "":
      return "Not Specified";
    case "AustralisIndigenae/Australoid":
      return "Aboriginal Australian";
    case "EoropeusAlbescens/Caucasoid":
      return "Caucasian / White / Middle Eastern";
    case "AfricanusNegreus/Negroid":
      return "African / African American / Black";
    case "AsiaticusFucus/Mongoloid":
      return "Asian / Indian / Eskimo";
    case "AsiaticusFucus/Pacificoid":
      return "Native Hawaiian / Pacific Islander";
    case "AmericanusRubescens":
      return "Native American / Alaska Native";
    case "AmericanusRubescens/Latinoid":
      return "Hispanic / Latino";
    case "Multiracial":
      return "Multiracial";
    case "Unknown":
      return "Unknown";
    default:
      return inputEthnicity;
  }
};

export const formatAttendeeStatus = (inputStatus) => {
  if (inputStatus === "Unconfirmed") {
    return "Invited";
  } else if (inputStatus === "Confirmed") {
    return "Confirmed";
  } else if (inputStatus === "Declined" || inputStatus === "Cancelled") {
    return "Declined";
  } else {
    return "";
  }
};

export const formatPhoneNumber = (inputString) => {
  const phoneNumber = inputString ? inputString.replace(/\D/g, "") : "";
  if (phoneNumber && parseInt(phoneNumber) > 0) {
    if (phoneNumber.length > 10) {
      return (
        phoneNumber.slice(0, 1) +
        "-" +
        phoneNumber.slice(1, 4) +
        "-" +
        phoneNumber.slice(4, 7) +
        "-" +
        phoneNumber.slice(7)
      );
    } else if (phoneNumber.length > 9) {
      return (
        "(" +
        phoneNumber.slice(0, 3) +
        ") " +
        phoneNumber.slice(3, 6) +
        "-" +
        phoneNumber.slice(6)
      );
    } else if (phoneNumber.length > 6) {
      return phoneNumber.slice(0, 3) + "-" + phoneNumber.slice(3);
    } else {
      return phoneNumber;
    }
  } else {
    return inputString;
  }
};

export const isBPCIPatient = (bpciStatus) => {
  return bpciStatus === 1 ? "Yes" : "No";
};

export const findContactByUserID = (inputUserId, contactArray) => {
  if (contactArray && Array.isArray(contactArray)) {
    for (let index in contactArray) {
      if (
        "id" in contactArray[index] &&
        contactArray[index].id === inputUserId
      ) {
        return contactArray[index];
      }
    }
  }
  return null;
};

export const determineAvailabilityHour = (index) => {
  switch (index) {
    case 0:
      return "0000";
    case 4:
      return "0100";
    case 8:
      return "0200";
    case 12:
      return "0300";
    case 16:
      return "0400";
    case 20:
      return "0500";
    case 24:
      return "0600";
    case 28:
      return "0700";
    case 32:
      return "0800";
    case 36:
      return "0900";
    case 40:
      return "1000";
    case 44:
      return "1100";
    case 48:
      return "1200";
    case 52:
      return "1300";
    case 56:
      return "1400";
    case 60:
      return "1500";
    case 64:
      return "1600";
    case 68:
      return "1700";
    case 72:
      return "1800";
    case 76:
      return "1900";
    case 80:
      return "2000";
    case 84:
      return "2100";
    case 88:
      return "2200";
    case 92:
      return "2300";
    default:
    //do nothing
  }
};

export const isIndustryRep = (userType) => {
  return userType && userType === 2 ? true : false;
};

export const isPHIConsented = (phiConsent) => {
  return phiConsent && phiConsent === 1 ? true : false;
};

// Input: array of users who have given the logged in user shared schedule access, and the user ID of the case owner
export const hasSharedCalendarPermission = (
  sharedScheduleArray,
  caseOwnerId
) => {
  if (Array.isArray(sharedScheduleArray) && parseInt(caseOwnerId)) {
    for (let i = 0; i < sharedScheduleArray.length; i++) {
      if (
        "id" in sharedScheduleArray[i] &&
        sharedScheduleArray[i].id === caseOwnerId
      ) {
        return true;
      }
    }
  }
  return false;
};

// is the user you are currently viewing as authorized to edit the case? (i.e. a provider attendee)
export const isAuthorizedEditor = (providersArray, userId) => {
  if (Array.isArray(providersArray) && parseInt(userId)) {
    for (let i = 0; i < providersArray.length; i++) {
      if ("id" in providersArray[i] && providersArray[i].id === userId) {
        return true;
      }
    }
  }
  return false;
};

export const hasDSLicense = (licenseArray, licenseKeyID) => {
  if (licenseKeyID && Array.isArray(licenseArray) && licenseArray.length > 0) {
    for (let i = 0; i < licenseArray.length; i++) {
      if (licenseArray[i].key && licenseArray[i].key === licenseKeyID) {
        return licenseArray[i].enabled;
      }
    }
  }
  return;
};

export const hasPracticeAdminAccess = (
  practicePermissionsArray,
  practiceId
) => {
  const practiceIdTemp = parseInt(practiceId);
  if (
    practiceIdTemp > 0 &&
    Array.isArray(practicePermissionsArray) &&
    practicePermissionsArray.length > 0
  ) {
    for (let i = 0; i < practicePermissionsArray.length; i++) {
      if (
        practicePermissionsArray[i].id &&
        practicePermissionsArray[i].id === practiceIdTemp
      ) {
        return practicePermissionsArray[i].permission === 3 ? true : false;
      }
    }
  }
  return;
};

export const handleLinkClick = (inputEndpoint) => {
  return window.location.assign(inputEndpoint);
};

// build the query params string
export const buildQueryParams = (params) =>
  Object.keys(params)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");

export const determineAgeFromDob = (dob) => {
  const parseDob = moment(dob, "YYYYMMDDHHmmss");
  const now = moment();
  return now.diff(parseDob, "years");
};

//check for valid input on mapping update/create
export const isValidInput = (row, type, key, rows) => {
  const value = rows ? rows.find((r) => r.id === row.id)[key] : row[key];
  let isValid = true;

  if (type === "days") {
    if (value) {
      const isValidDayString = /^[MTWRFSU]*$/.test(value);
      isValid = isValid && isValidDayString;
    }
  } else if (type === "integer") {
    const isValidInteger = /^[0-9]+$/.test(value);
    isValid = isValid && isValidInteger;
  } else if (type === "iso") {
    if (
      !moment(value).isValid() &&
      !moment(value, "h:mm A", true).isValid() &&
      !moment(value, "MM/DD/YYYY", true).isValid()
    ) {
      isValid = false;
    }
  } else if (
    type === "timestamp" &&
    key !== "timestamp" &&
    key !== "create_date"
  ) {
    const isValidDateString = /^\d{0,14}$/.test(value);
    const isValidTimestamp = moment(value, "YYYYMMDDHHmmss", true).isValid();
    isValid =
      isValid &&
      ((isValidDateString && isValidTimestamp) ||
        moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid());
  } else if (type === "array") {
    const arrayTest = /^[0-9,\s]+$/;
    isValid = Array.isArray(value) || arrayTest.test(value) || value === "";
  } else if (type === "string-list") {
    if (value) {
      const isStringList = /^\d+(,\d+)*$/;
      isValid = isStringList.test(value);
    }
  }
  return isValid;
};

export const isAllValid = (row, headers) => {
  let allValid = true;
  let missingKeys = [];

  headers.forEach((header) => {
    const { key, type, isRequired } = header;
    const value = row[key];

    // Check required fields
    if (
      isRequired &&
      (value === null || value === undefined || value === "" || value === 0)
    ) {
      missingKeys.push(header.name);
      allValid = false;
    }

    const isValid = isValidInput(row, type, key);
    if (!isValid) {
      allValid = false; // If any field is invalid, set the form to invalid
    }
  });

  return { allValid, missingKeys };
};
