import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Checkbox from "../Checkbox/Checkbox";
import CommitCheck from "../../assets/CommitCheck.svg";
import EditIcon from "../../assets/EditIcon.svg";
import UncommitIcon from "../../assets/UncommitIcon.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import TableDropdown from "../TableDropdown/TableDropdown";
import { isValidInput } from "../../libraries/helpers";
import AddIcon from "../../assets/AddIcon.svg";
import moment from "moment";
import { Context as MappingContext } from "../../context/MappingContext";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";

const initialMapping = {
  id: Date.now(),
  integration_patient_engagement_id: 0,
  user_id: 0,
  npi: 0,
  go_live_date: moment().format("YYYYMMDDHHmmss"),
  from_calendar_id: 0,
  source: "JNJ",
  active: "INACTIVE",
  create_date: null,
  surgeon_name: "",
  start_time: moment().format("YYYYMMDDHHmmss"),
  time_zone: "America/New_York",
  attendees: "",
  show_patient_name: false,
  allowed_procedures: "",
  trigger_attendees: 0,
  show_full_patient_last_name: false,
  required_devices: "",
  trigger_whitelist: "",
  trigger_whitelist_field_reference: "",
  trigger_blacklist: "",
};

export const surgeonHeaders = [
  { name: "ID", key: "id", isRequired: false },
  { name: "User ID", key: "user_id", type: "number", isRequired: true },
  { name: "NPI", key: "npi", type: "number", isRequired: false },
  {
    name: "GO Live Date",
    key: "go_live_date",
    type: "timestamp",
    isRequired: false,
  },
  {
    name: "From Calendar ID",
    key: "from_calendar_id",
    type: "number",
    isRequired: false,
  },
  { name: "Source", key: "source", isRequired: false },
  {
    name: "Active",
    key: "active",
    type: "boolean",
    text: "Is Active?",
    isRequired: false,
  },
  {
    name: "Create Date",
    key: "create_date",
    type: "timestamp",
    isRequired: false,
  },
  { name: "Surgeon Name", key: "surgeon_name", isRequired: false },
  {
    name: "Start Time",
    key: "start_time",
    type: "timestamp",
    isRequired: false,
  },
  {
    name: "Time Zone",
    key: "time_zone",
    type: "dropdown",
    options: [
      { value: "America/New_York", name: "America/New_York" },
      { value: "America/Chicago", name: "America/Chicago" },
      { value: "America/Phoenix", name: "America/Phoenix" },
      { value: "America/Los_Angeles", name: "America/Los_Angeles" },
    ],
    isRequired: false,
  },
  { name: "Attendees", key: "attendees", type: "array", isRequired: false },
  {
    name: "Show Patient Name",
    key: "show_patient_name",
    type: "boolean",
    text: "Show Patient Name?",
    isRequired: true,
  },
  { name: "Allowed Procedures", key: "allowed_procedures", isRequired: false },
  {
    name: "Trigger Attendees",
    key: "trigger_attendees",
    type: "number",
    isRequired: false,
  },
  {
    name: "Show Patient Last Name",
    key: "show_full_patient_last_name",
    type: "boolean",
    text: "Show Patient Last Name?",
    isRequired: false,
  },
  { name: "Required Devices", key: "required_devices", isRequired: false },
  { name: "Trigger Whitelist", key: "trigger_whitelist", isRequired: false },
  {
    name: "Field Reference",
    key: "trigger_whitelist_field_reference",
    isRequired: false,
  },
  { name: "Trigger Backlist", key: "trigger_blacklist", isRequired: false },
];

//#region styled
const Scrollable = styled.div`
  width: 98%;
  overflow-x: auto;
  white-space: nowrap;
  margin: 15px;
  position: relative;
  border-radius: 10px;
  background-color: #ffffff;
  height: 80vh;
  border: 1px solid #ebebeb;

  @media (max-width: 1031px) {
    height: 75vh;
  }
`;

const SurgeonTable = styled.table`
  width: 100%;
  padding: 5px;
  height: 100%;
  table-layout: fixed;
  min-width: 4000px;

  thead {
    border-bottom: 2px solid #0096fa;
    background-color: white;
    position: sticky;
    top: 0;
    z-index: 11;
    display: grid;
    width: 100%;
  }

  tr {
    display: grid;
    height: fit-content;
    grid-template-columns: 0.5fr 0.75fr 0.75fr 0.75fr 1fr 1fr 1.5fr 0.65fr 1fr 1fr 0.75fr 1fr 1fr 1fr 1fr 1fr 1.25fr 1fr 1fr 1fr 1fr;

    td {
      width: auto;
    }
  }

  .disabled {
    pointer-events: none;
    opacity: 35%;
  }

  .actions {
    position: sticky;
    left: 0;
    background: white;
    z-index: 3;
    width: 100%;

    .main-actions {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .create {
      border: 1px solid #0096fa;

      &:hover {
        background-color: #0096fa;
      }
    }

    .delete {
      border: 1px solid #ff0000;

      &:hover {
        background-color: #ff0000;
      }
    }

    .delete,
    .create {
      border-radius: 3px;
      height: 20px;
      width: 20px;
      cursor: pointer;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        img {
          filter: brightness(0) invert(1);
        }
      }

      img {
        padding: 5px;
        vertical-align: baseline;
      }
    }

    .item {
      display: flex;
      gap: 5px;
      cursor: pointer;
      text-align: left;
      font-size: 14px;
    }
  }

  th {
    font-weight: bold;
    font-size: 1rem;
    border-right: 1px solid #dee2e6;

    .main-actions {
      margin: 15px 0 12px 10px;
    }

    p {
      margin: auto 0 auto 10px;
      text-align: left;
      padding: 10px 0;
    }
  }
`;

const SurgeonTableData = styled.tbody`
  font-size: 1rem;
  display: block;
  border-spacing: 0;

  .attendees-container {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    width: 100%;
  }

  p {
    margin: 0 !important;
    font-size: 0.875rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
    min-width: 0;
  }

  .none {
    font-style: italic;
    color: #b9b9b9;
  }

  .default-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  tr {
    border-bottom: 1px solid #d9efff;
    display: grid;
    height: fit-content;

    &.committed {
      border: 1px solid #0096fa;
    }

    td {
      padding: 10px;
      border-right: 1px solid #dee2e6;

      &.dropdown {
        overflow: visible !important;
      }

      &:not(:first-child) {
        text-align: left;
        display: flex;
        align-items: flex-start;
        overflow: hidden;
      }
    }
  }

  input,
  textarea {
    border: none;
    background-color: #f3f3f3;
    border-radius: 5px;
    color: #0096fa;
    width: 100%;

    &.error {
      border: 1px solid #ff0000;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .actions {
    padding-left: 10px;
  }

  .ACTIVE,
  .INACTIVE {
    text-transform: uppercase;
  }

  .ACTIVE {
    color: #52e100;
  }

  .INACTIVE {
    color: #ff0000;
  }
`;

const AttendeePill = styled.div`
  padding: 3px 7px;
  background-color: #f8f8f8;
  border: 1px solid #d8d8d8;
  border-radius: 50px;
  width: fit-content;

  p {
    font-size: 11px;
  }
`;

const BooleanInput = styled.div`
  display: flex;
  gap: 5px;
  font-size: 10px !important;
  background-color: #f3f3f3;
  border-radius: 2px;
  align-items: center;
  padding: 1px 5px;
  min-height: 28px;
`;
//#endregion

const SurgeonMappingsTable = () => {
  const {
    state: mappingState,
    getSurgeonMappings,
    setSelectedSurgeons,
    setSelectAllSurgeons,
    setSelectedSurgeonMapping,
    toggleModal,
    setCreatingSurgeons,
    setNewSurgeons,
    setEditedSurgeonRows,
    setCommitedSurgeonRows,
    getSurgeonMappingsById,
  } = useContext(MappingContext);
  // local state for testing

  // remove double clicking checkbox for select all
  useEffect(() => {
    if (
      mappingState.surgeonMappings.length !== 0 &&
      mappingState.selectedSurgeonMappings.length ===
        mappingState.surgeonMappings.length
    ) {
      setSelectAllSurgeons(true);
    }
  }, [mappingState.selectedSurgeonMappings, mappingState.surgeonMappings]);

  useEffect(() => {
    if (mappingState.searchValue !== "") {
      // delay search
      const timeout = setTimeout(() => {
        getSurgeonMappingsById(mappingState.searchValue);
      }, 1000);
      return () => clearTimeout(timeout);
    } else {
      getSurgeonMappings(mappingState.physician);
    }
  }, [mappingState.searchValue, mappingState.physician]);

  const handleDelete = (row) => {
    setSelectedSurgeonMapping(row);
    toggleModal(true);
  };

  const handleCreate = () => {
    if (mappingState.creatingSurgeonMapping) {
      const newMapping = {
        ...initialMapping,
        id: Date.now(),
      };
      setNewSurgeons([...mappingState.newSurgeonMappings, { ...newMapping }]);
    } else {
      setSelectedSurgeons([]);
      setCreatingSurgeons(true);
      setNewSurgeons([initialMapping]);
    }
  };

  useEffect(() => {
    if (mappingState.isSelectAllSurgeons) {
      setSelectedSurgeons(
        mappingState.surgeonMappings.map((mapping) => mapping.id)
      );
    }
  }, [mappingState.isSelectAllSurgeons]);

  const handleMultiSelect = () => {
    if (mappingState.isSelectAllSurgeons) {
      setSelectAllSurgeons(false);
      setSelectedSurgeons([]);
    } else {
      setSelectAllSurgeons(!mappingState.isSelectAllSurgeons);
    }
  };

  const setEditMapping = (mapping) => {
    const formattedMapping = {
      ...mapping,
      attendees: mapping.attendees.map((value) => value.user_id).join(", "),
      go_live_date:
        mapping.go_live_date !== ""
          ? moment(mapping.go_live_date).format("YYYYMMDDhhmmss")
          : "",
      start_time: moment(mapping.start_time, "h:mm A").format("YYYYMMDDhhmmss"),
    };
    setEditedSurgeonRows(
      mappingState.editedSurgeonRows.some((r) => r.id === mapping.id)
        ? mappingState.editedSurgeonRows
        : [...mappingState.editedSurgeonRows, { ...formattedMapping }]
    );
  };

  const updateMapping = (id, key, value) => {
    if (mappingState.creatingSurgeonMapping) {
      const updatedRows = mappingState.newSurgeonMappings.map((row) =>
        row.id === id ? { ...row, [key]: value } : row
      );
      setNewSurgeons(updatedRows);
    } else {
      const updatedRows = mappingState.editedSurgeonRows.map((row) =>
        row.id === id ? { ...row, [key]: value } : row
      );
      setEditedSurgeonRows(updatedRows);
    }
  };

  const handleCommit = (row) => {
    let editRow;
    if (mappingState.creatingSurgeonMapping) {
      editRow = mappingState.newSurgeonMappings.find((r) => r.id === row.id);
    } else {
      editRow = mappingState.editedSurgeonRows.find((r) => r.id === row.id);
    }

    const fixedAttendees = editRow.attendees
      .split(",")
      .map((id) => id.trim())
      .filter((id) => id !== "")
      .map((id) => ({ user_id: parseInt(id) }));

    const formattedMapping = {
      ...editRow,
      attendees: fixedAttendees,
      go_live_date: moment(editRow.go_live_date, "YYYYMMDDhhmmss").format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
      start_time: moment(editRow.start_time, "YYYYMMDDhhmmss").format(
        "YYYY-MM-DDTHH:mm:ss"
      ),
    };
    setCommitedSurgeonRows(formattedMapping);
  };

  const handleLoadMoreSurgonMappings = () => {
    getSurgeonMappings(mappingState.physician, mappingState.surgeonsCursor);
  };

  const surgeonsObserverRef = useIntersectionObserver({
    callback: handleLoadMoreSurgonMappings,
    cursor: mappingState.surgeonsCursor,
  });

  return (
    <Scrollable>
      <SurgeonTable>
        <thead>
          <tr>
            <th className="actions">
              <div
                className={`main-actions ${
                  mappingState.editedSurgeonRows.length > 0 ? `disabled` : ``
                }`}
              >
                <Checkbox
                  toggled={
                    mappingState.isSelectAllSurgeons ||
                    (mappingState.surgeonMappings.length !== 0 &&
                      mappingState.selectedSurgeonMappings.length ===
                        mappingState.surgeonMappings.length)
                  }
                  multiSelect={mappingState.selectedSurgeonMappings.length > 1}
                  clickedFunction={handleMultiSelect}
                  disabled={mappingState.creatingSurgeonMapping}
                />
                {mappingState.isSelectAllSurgeons ||
                mappingState.selectedSurgeonMappings.length > 0 ? (
                  <div className="delete">
                    <img
                      onClick={() => handleDelete()}
                      src={DeleteIcon}
                      alt="delete-mappings"
                    />
                  </div>
                ) : (
                  <div className="create">
                    <img
                      onClick={() => handleCreate()}
                      src={AddIcon}
                      alt="create-mappings"
                    />
                  </div>
                )}
              </div>
            </th>
            {surgeonHeaders.map((header, idx) => (
              <th className={header.key} key={idx}>
                <p>{header.name}</p>
              </th>
            ))}
          </tr>
        </thead>
        <SurgeonTableData>
          {mappingState.newSurgeonMappings &&
            mappingState.newSurgeonMappings.map((mapping) => (
              <tr
                key={mapping.id}
                className={
                  mappingState.commitedSurgeonRows.some(
                    (r) => r.id === mapping.id
                  )
                    ? "committed"
                    : ""
                }
              >
                <td className="actions">
                  {!mappingState.commitedSurgeonRows.some(
                    (r) => r.id === mapping.id
                  ) ? (
                    <div className="item" onClick={() => handleCommit(mapping)}>
                      <img src={CommitCheck} alt="confirm" />
                      <span style={{ color: "#002855" }}>Commit</span>
                    </div>
                  ) : (
                    <div className="item" onClick={() => handleCommit(mapping)}>
                      <img src={UncommitIcon} alt="uncommit" />
                      <span style={{ color: "#FF0000" }}>Uncommit</span>
                    </div>
                  )}
                </td>
                {surgeonHeaders.map((header, idx) => {
                  return (
                    <td className={`${header.name} ${header.type}`} key={idx}>
                      {header.type === "number" ? (
                        <input
                          type="number"
                          name={header.key}
                          disabled={mappingState.commitedSurgeonRows.some(
                            (r) => r.id === mapping.id
                          )}
                          value={
                            mappingState.newSurgeonMappings.find(
                              (r) => r.id === mapping.id
                            )[header.key] || ""
                          }
                          onChange={(e) =>
                            updateMapping(
                              mapping.id,
                              e.target.name,
                              parseInt(e.target.value)
                            )
                          }
                        />
                      ) : header.type === "dropdown" ? (
                        <TableDropdown
                          placeholder={
                            mappingState.newSurgeonMappings.find(
                              (r) => r.id === mapping.id
                            )[header.key]
                          }
                          options={header.options}
                          onSelect={(value) =>
                            updateMapping(mapping.id, header.key, value)
                          }
                          disabled={mappingState.commitedSurgeonRows.some(
                            (r) => r.id === mapping.id
                          )}
                        />
                      ) : header.type === "boolean" ? (
                        <BooleanInput>
                          <Checkbox
                            toggled={
                              header.key === "active"
                                ? mappingState.newSurgeonMappings.find(
                                    (r) => r.id === mapping.id
                                  )[header.key] === "ACTIVE"
                                : mappingState.newSurgeonMappings.find(
                                    (r) => r.id === mapping.id
                                  )[header.key]
                            }
                            size="15px"
                            clickedFunction={
                              !mappingState.commitedSurgeonRows.some(
                                (r) => r.id === mapping.id
                              )
                                ? header.key === "active"
                                  ? () => {
                                      const status =
                                        mappingState.newSurgeonMappings.find(
                                          (r) => r.id === mapping.id
                                        )[header.key] === "ACTIVE"
                                          ? "INACTIVE"
                                          : "ACTIVE";

                                      updateMapping(
                                        mapping.id,
                                        header.key,
                                        status
                                      );
                                    }
                                  : () =>
                                      updateMapping(
                                        mapping.id,
                                        header.key,
                                        !mappingState.newSurgeonMappings.find(
                                          (r) => r.id === mapping.id
                                        )[header.key]
                                      )
                                : () => {}
                            }
                          />
                          <p style={{ color: "#000", textTransform: "none" }}>
                            {header.text}
                          </p>
                        </BooleanInput>
                      ) : header.type === "array" ? (
                        <input
                          type="text"
                          name={header.key}
                          disabled={
                            mappingState.commitedSurgeonRows.some(
                              (r) => r.id === mapping.id
                            ) || header.key === "id"
                          }
                          value={
                            mappingState.newSurgeonMappings.find(
                              (r) => r.id === mapping.id
                            )[header.key] || ""
                          }
                          onChange={(e) =>
                            updateMapping(
                              mapping.id,
                              e.target.name,
                              e.target.value
                            )
                          }
                        />
                      ) : (
                        <input
                          type="text"
                          name={header.key}
                          className={`${
                            isValidInput(
                              mapping,
                              header.type,
                              header.key,
                              mappingState.newSurgeonMappings
                            )
                              ? ""
                              : "error"
                          }`}
                          disabled={
                            mappingState.commitedSurgeonRows.some(
                              (r) => r.id === mapping.id
                            ) ||
                            header.key === "id" ||
                            header.key === "create_date" ||
                            header.key === "source"
                          }
                          value={
                            header.key === "id" || header.key === "create_date"
                              ? "Locked"
                              : mappingState.newSurgeonMappings.find(
                                  (r) => r.id === mapping.id
                                )[header.key] || ""
                          }
                          onChange={(e) =>
                            updateMapping(
                              mapping.id,
                              e.target.name,
                              e.target.value
                            )
                          }
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          {mappingState.surgeonMappings &&
            mappingState.surgeonMappings.map((mapping, idx) => (
              <tr
                key={mapping.id}
                className={
                  mappingState.commitedSurgeonRows.some(
                    (r) => r.id === mapping.id
                  )
                    ? "committed"
                    : mappingState.creatingSurgeonMapping
                    ? "disabled"
                    : ""
                }
              >
                <td className="actions">
                  {!mappingState.editedSurgeonRows.some(
                    (r) => r.id === mapping.id
                  ) ? (
                    mappingState.editedSurgeonRows.length === 0 ? (
                      <div className="default-actions">
                        <Checkbox
                          toggled={mappingState.selectedSurgeonMappings.includes(
                            mapping.id
                          )}
                          clickedFunction={() => {
                            setSelectedSurgeons(
                              !mappingState.selectedSurgeonMappings.includes(
                                mapping.id
                              )
                                ? [
                                    ...mappingState.selectedSurgeonMappings,
                                    mapping.id,
                                  ]
                                : mappingState.selectedSurgeonMappings.filter(
                                    (id) => id !== mapping.id
                                  )
                            );
                          }}
                        />
                        <div
                          className="item"
                          onClick={() => setEditMapping(mapping)}
                        >
                          <img src={EditIcon} alt="edit" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="item"
                        onClick={() => setEditMapping(mapping)}
                      >
                        <img src={EditIcon} alt="edit" />
                        <span style={{ color: "#0096fa" }}>Edit</span>
                      </div>
                    )
                  ) : !mappingState.commitedSurgeonRows.some(
                      (r) => r.id === mapping.id
                    ) ? (
                    <div className="item" onClick={() => handleCommit(mapping)}>
                      <img src={CommitCheck} alt="confirm" />
                      <span style={{ color: "#002855" }}>Commit</span>
                    </div>
                  ) : (
                    <div className="item" onClick={() => handleCommit(mapping)}>
                      <img src={UncommitIcon} alt="uncommit" />
                      <span style={{ color: "#FF0000" }}>Uncommit</span>
                    </div>
                  )}
                </td>
                {surgeonHeaders.map((header, idx) => {
                  return (
                    <td className={`${header.name} ${header.type}`} key={idx}>
                      {mappingState.editedSurgeonRows.some(
                        (r) => r.id === mapping.id
                      ) ? (
                        header.type === "number" ? (
                          <input
                            type="number"
                            name={header.key}
                            disabled={mappingState.commitedSurgeonRows.some(
                              (r) => r.id === mapping.id
                            )}
                            value={
                              mappingState.editedSurgeonRows.find(
                                (r) => r.id === mapping.id
                              )[header.key] || ""
                            }
                            onChange={(e) =>
                              updateMapping(
                                mapping.id,
                                e.target.name,
                                parseInt(e.target.value)
                              )
                            }
                          />
                        ) : header.type === "dropdown" ? (
                          <TableDropdown
                            placeholder={
                              mappingState.editedSurgeonRows.find(
                                (r) => r.id === mapping.id
                              )[header.key]
                            }
                            options={header.options}
                            onSelect={(value) =>
                              updateMapping(mapping.id, header.key, value)
                            }
                            disabled={mappingState.commitedSurgeonRows.some(
                              (r) => r.id === mapping.id
                            )}
                          />
                        ) : header.type === "boolean" ? (
                          <BooleanInput>
                            <Checkbox
                              toggled={
                                header.key === "active"
                                  ? mappingState.editedSurgeonRows.find(
                                      (r) => r.id === mapping.id
                                    )[header.key] === "ACTIVE"
                                  : mappingState.editedSurgeonRows.find(
                                      (r) => r.id === mapping.id
                                    )[header.key]
                              }
                              size="15px"
                              clickedFunction={
                                !mappingState.commitedSurgeonRows.some(
                                  (r) => r.id === mapping.id
                                )
                                  ? header.key === "active"
                                    ? () => {
                                        const status =
                                          mappingState.editedSurgeonRows.find(
                                            (r) => r.id === mapping.id
                                          )[header.key] === "ACTIVE"
                                            ? "INACTIVE"
                                            : "ACTIVE";

                                        updateMapping(
                                          mapping.id,
                                          header.key,
                                          status
                                        );
                                      }
                                    : () =>
                                        updateMapping(
                                          mapping.id,
                                          header.key,
                                          !mappingState.editedSurgeonRows.find(
                                            (r) => r.id === mapping.id
                                          )[header.key]
                                        )
                                  : () => {}
                              }
                            />
                            <p style={{ color: "#000", textTransform: "none" }}>
                              {header.text}
                            </p>
                          </BooleanInput>
                        ) : header.type === "array" ? (
                          <input
                            type="text"
                            className={`${
                              isValidInput(
                                mapping,
                                header.type,
                                header.key,
                                mappingState.editedSurgeonRows
                              )
                                ? ""
                                : "error"
                            }`}
                            name={header.key}
                            disabled={
                              mappingState.commitedSurgeonRows.some(
                                (r) => r.id === mapping.id
                              ) || header.key === "id"
                            }
                            value={
                              mappingState.editedSurgeonRows.find(
                                (r) => r.id === mapping.id
                              )[header.key] || ""
                            }
                            onChange={(e) =>
                              updateMapping(
                                mapping.id,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        ) : (
                          <input
                            type="text"
                            name={header.key}
                            className={`${
                              isValidInput(
                                mapping,
                                header.type,
                                header.key,
                                mappingState.editedSurgeonRows
                              )
                                ? ""
                                : "error"
                            }`}
                            disabled={
                              mappingState.commitedSurgeonRows.some(
                                (r) => r.id === mapping.id
                              ) ||
                              header.key === "id" ||
                              header.key === "create_date" ||
                              header.key === "source"
                            }
                            value={
                              mappingState.editedSurgeonRows.find(
                                (r) => r.id === mapping.id
                              )[header.key] || ""
                            }
                            onChange={(e) =>
                              updateMapping(
                                mapping.id,
                                e.target.name,
                                e.target.value
                              )
                            }
                          />
                        )
                      ) : header.type === "timestamp" ? (
                        mapping[header.key] === "" ? (
                          <p className="none">No Date Assigned</p>
                        ) : header.key.includes("date") ? (
                          <p>
                            {moment(
                              mapping[header.key],
                              "YYYY-MM-DDTHH:mm:ss"
                            ).format("MMM DD, YYYY")}
                          </p>
                        ) : (
                          <p>
                            {moment(
                              mapping[header.key],
                              "YYYY-MM-DDTHH:mm:sss"
                            ).format("h:mm A")}
                          </p>
                        )
                      ) : header.type === "array" ? (
                        mapping.attendees.length > 0 ? (
                          <div className="attendees-container">
                            {mapping.attendees.map((attendee) => (
                              <AttendeePill key={`atted${attendee.user_id}`}>
                                <p>
                                  {attendee.first && attendee.last
                                    ? `${attendee.first} ${attendee.last}`
                                    : attendee.user_id}
                                </p>
                              </AttendeePill>
                            ))}
                          </div>
                        ) : (
                          <p className="none">No Attendees</p>
                        )
                      ) : header.key === "active" ? (
                        <p className={mapping.active}>{mapping.active}</p>
                      ) : header.key.includes("show") ? (
                        <p
                          className={
                            mapping[header.key] ? "ACTIVE" : "INACTIVE"
                          }
                        >
                          {mapping[header.key] ? "True" : "False"}
                        </p>
                      ) : mapping[header.key] === "" ||
                        mapping[header.key] === null ? (
                        <p className="none">N/A</p>
                      ) : (
                        <p>{mapping[header.key]}</p>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          <tr
            ref={surgeonsObserverRef}
            style={{
              visibility: "hidden",
              height: 0,
              padding: 0,
              minHeight: 0,
            }}
          />
        </SurgeonTableData>
      </SurgeonTable>
    </Scrollable>
  );
};

export default SurgeonMappingsTable;
