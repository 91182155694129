import React, { useContext, useEffect } from "react";
import { Context as MappingContext } from "../../context/MappingContext";
import { Context as EventContext } from "../../context/EventContext";

import BuildingIcon from "../../assets/Building.svg";
import DoctorIcon from "../../assets/Doctor.svg";
import CloseIcon from "../../assets/Close.svg";
import CommitIcon from "../../assets/CommitSendIcon.svg";

import styled from "styled-components";
import DocSperaDropdown from "../Dropdown/DocSperaDropdown";
import { SearchBar } from "../SearchBar/SearchBar";
import DocSperaButton from "../DocSperaButton/DocSperaButton";
import { AlomDecodeType2, isAllValid } from "../../libraries/helpers";
import { locationHeaders } from "../LocationMappings/LocationMappings";
import { surgeonHeaders } from "../SurgeonMappingsTable/SurgeonMappingsTable";
import toast from "react-hot-toast";

const MappingsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 15px;
  min-height: 58px;

  .row {
    display: inline-flex;
    flex-direction: row;
  }

  h2 {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0;

    .mapping-icon {
      padding: 8px;
    }
  }
`;

const MappingsHeading = (props) => {
  const { state: eventState } = useContext(EventContext);
  const {
    state: mappingState,
    setLocationSearch,
    setPhysicianFilter,
    setLocationFilter,
    clearFilters,
    setCreatingSurgeons,
    setEditedLocationRows,
    setCommitedLocationRows,
    updateLocationMappings,
    cancelAddLocationRows,
    setNewSurgeons,
    setEditedSurgeonRows,
    setCommitedSurgeonRows,
    updateSurgeonMappings,
    setSurgeonSearch,
    getLocations,
    resetErrorMessage,
  } = useContext(MappingContext);

  // getLocations
  useEffect(() => {
    getLocations();
  }, []);

  useEffect(() => {
    if (mappingState.errorMessage) {
      toast(mappingState.errorMessage);
      resetErrorMessage("");
    }
  }, [mappingState.errorMessage]);

  //locations/physicians filter dropdown
  const filterDropdown = function (list, isPhysician) {
    if (!list.length) return [];
    return list.map((obj) => {
      if (isPhysician) {
        return {
          value: AlomDecodeType2(obj.id.toString()),
          name: obj.name,
        };
      } else {
        return {
          value: obj.id,
          name: obj.name,
        };
      }
    });
  };

  const handleCancelCommit = () => {
    if (mappingState.isLocation) {
      mappingState.isCreateLocationMapping && cancelAddLocationRows();
      setEditedLocationRows([]);
      setCommitedLocationRows([]);
    } else {
      setCreatingSurgeons(false);
      setNewSurgeons();
      setEditedSurgeonRows([]);
      setCommitedSurgeonRows([]);
    }
  };

  const handleSubmitCommit = () => {
    // api bulk edit/create
    const isLocation = mappingState.isLocation;
    let rows = isLocation
      ? mappingState.commitedLocationRows
      : mappingState.commitedSurgeonRows;
    const headers = isLocation ? locationHeaders : surgeonHeaders;
    const requiredFields = headers
      .filter((header) => header.isRequired)
      .map((header) => header.name);

    const invalidRows = rows.map((row) => isAllValid(row, headers));
    const allValid = invalidRows.every((result) => result.allValid);
    const hasMissingFields = invalidRows.some(
      (result) => result.missingKeys.length
    );

    // remove key: value pairs from object if value is 0, "", or null
    rows = rows.map((row) =>
      Object.fromEntries(
        Object.entries(row).filter(([_, value]) =>
          Array.isArray(value)
            ? value.length > 0
            : value !== 0 && value !== "" && value !== null
        )
      )
    );

    if (
      mappingState.isCreateLocationMapping ||
      mappingState.creatingSurgeonMapping
    ) {
      rows = rows.map(({ id, ...row }) => row);
    }

    if (allValid) {
      if (isLocation) {
        updateLocationMappings(rows);

        // get updated locations list
        getLocations();
      } else {
        updateSurgeonMappings(rows);
      }
    } else {
      hasMissingFields &&
        toast(
          `The following fields are required: ${requiredFields.join(", ")}`
        );
      toast("Error: Some fields are invalid. Please check your inputs.");
    }
  };

  return (
    <MappingsHeader>
      <h2>
        <img
          className="mapping-icon"
          height={50}
          width={50}
          src={mappingState.isLocation ? BuildingIcon : DoctorIcon}
          alt="mapping-icon"
        />
        {mappingState.isLocation ? "Location" : "Surgeon"} Mappings
      </h2>
      <div className="item-align">
        {mappingState.editedLocationRows.length === 0 &&
        mappingState.editedSurgeonRows.length === 0 &&
        !mappingState.creatingSurgeonMapping ? (
          <>
            {/* filters */}
            <div style={{ width: "350px" }}>
              <p className="Heading-guide">Search</p>
              <SearchBar
                placeholder="Search By Mapping ID"
                grabbedValue={mappingState.searchValue}
                valueChangeHandler={
                  mappingState.isLocation
                    ? (e) => setLocationSearch(e.target.value)
                    : (e) => setSurgeonSearch(e.target.value)
                }
              />
            </div>
            <div style={{ width: "180px" }} className="ms-2">
              <DocSperaDropdown
                name="physician"
                dropdownTitle="Physician"
                selectedOption={mappingState.physician || "All"}
                optionLists={[
                  { value: "", name: "All" },
                  ...filterDropdown(eventState.physicians, true),
                ]}
                optionSelectHandler={(name, value) => {
                  setPhysicianFilter(value === "All" ? "" : value);
                }}
                showViewOption={true}
              />
            </div>
            {mappingState.isLocation && (
              <div style={{ width: "180px" }} className="ms-2">
                <DocSperaDropdown
                  name="location"
                  dropdownTitle="Location"
                  selectedOption={mappingState.location || "All"}
                  optionLists={[
                    { value: "", name: "All" },
                    ...filterDropdown(mappingState.locations, false),
                  ]}
                  optionSelectHandler={(name, value) => {
                    setLocationFilter(value === "All" ? "" : value);
                  }}
                  showViewOption={true}
                />
              </div>
            )}
            <div className="ms-2 Heading-clear">
              <DocSperaButton
                className="primary"
                label="Clear Filters"
                icon={CloseIcon}
                height="15px"
                width="15px"
                onPress={clearFilters}
                disabled={
                  !mappingState.searchValue &&
                  !mappingState.physician &&
                  !mappingState.location
                }
              />
            </div>
          </>
        ) : (
          <>
            {/* Commit */}
            <div className="Heading-clear">
              <DocSperaButton
                className="primary"
                label="Cancel"
                onPress={handleCancelCommit}
              />
            </div>
            <div className="ms-2 Heading-clear">
              <DocSperaButton
                className="primary"
                label={`Commit ${
                  mappingState.isLocation
                    ? mappingState.commitedLocationRows.length
                    : mappingState.commitedSurgeonRows.length
                } Actions`}
                icon={CommitIcon}
                height="19px"
                width="19px"
                onPress={handleSubmitCommit}
                disabled={
                  mappingState.commitedLocationRows.length === 0 &&
                  mappingState.commitedSurgeonRows.length === 0
                }
              />
            </div>
          </>
        )}
      </div>
    </MappingsHeader>
  );
};

export default MappingsHeading;
