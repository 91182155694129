import styled from "styled-components";
import Check from "../../assets/CheckSVG.svg";
import DashIcon from "../../assets/DashIcon.svg";

const StyledCheckbox = styled.div`
  background-color: #fff;
  border: 1px solid #0096fa;
  border-radius: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  pointer-events: ${(props) => props.$disabled && "none"};
  border-color: ${(props) => props.$disabled && "rgba(0, 150, 250, 0.5)"};

  img {
    height: 50%;
    width: auto;

    &.dash {
      width: 75%;
      height: auto;
    }
  }
`;

function Checkbox({
  toggled,
  clickedFunction,
  size = "20px",
  multiSelect,
  disabled,
}) {
  return (
    <StyledCheckbox
      style={
        toggled || multiSelect
          ? { backgroundColor: "#0096fa", height: size, width: size }
          : { height: size, width: size }
      }
      onClick={clickedFunction}
      $disabled={disabled}
    >
      <img
        className={!toggled && multiSelect ? "dash" : ""}
        src={!toggled && multiSelect ? DashIcon : Check}
        alt="checked"
      />
    </StyledCheckbox>
  );
}

export default Checkbox;
