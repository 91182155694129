import createDataContext from "./createDataContext";
import locationMappingsInstance from "../api/location-mappings-api";
import surgeonMappingsInstance from "../api/surgeon-mappings-api";

let initialState = {
  commit: false,
  isLocation: true,
  searchValue: "",
  physician: "",
  location: "",
  locations: [],
  surgeonMappings: [],
  surgeonsCursor: "",
  selectedSurgeonMappings: [],
  isSelectAllSurgeons: false,
  locationMappings: [],
  locationsCursor: "",
  selectedLocationMapping: null,
  selectedSurgeonMapping: null,
  editedSurgeonRows: [],
  selectedLocations: [],
  isModalOpen: false,
  creatingSurgeonMapping: false,
  editedLocationRows: [],
  commitedLocationRows: [],
  isCreateLocationMapping: false,
  commitedSurgeonRows: [],
  newSurgeonMappings: [],
};

const mappingReducer = (state, action) => {
  switch (action.type) {
    case "toggle-mapping":
      return {
        ...state,
        isLocation: action.payload,
      };
    case "set-location-search":
      return {
        ...state,
        searchValue: action.payload,
      };
    case "set-physician-filter":
      return {
        ...state,
        physician: action.payload,
      };
    case "set-location-filter":
      return {
        ...state,
        location: action.payload,
      };
    case "set-locations":
      return {
        ...state,
        locations: action.payload,
      };
    case "reset-filters":
      return {
        ...state,
        searchValue: "",
        physician: "",
        location: "",
      };
    case "set-surgeon-mappings":
      return {
        ...state,
        surgeonMappings: action.payload.prevCursor
          ? [...state.surgeonMappings, ...action.payload.surgeonMappings]
          : action.payload.surgeonMappings,
        surgeonsCursor: action.payload.cursor,
      };
    case "set-selected-surgeon-mappings":
      return {
        ...state,
        selectedSurgeonMappings: action.payload,
      };
    case "set-select-all-surgeon":
      return {
        ...state,
        isSelectAllSurgeons: action.payload,
      };
    case "set-location-mappings":
      return {
        ...state,
        locationMappings: action.payload.prevCursor
          ? [...state.locationMappings, ...action.payload.locationMappings]
          : action.payload.locationMappings,
        locationsCursor: action.payload.cursor,
      };
    case "update-location-mappings": {
      let updatedMappings = state.locationMappings.map((row) => {
        const change = action.payload.find((change) => change.id === row.id);
        return change ? { ...row, ...change } : row;
      });

      // add new mappings
      const newMappings = action.payload
        .filter(
          (change) =>
            !state.locationMappings.some((row) => row.id === change.id)
        )
        .sort((a, b) => b.id - a.id);

      // remove temp new mappings
      if (state.isCreateLocationMapping) {
        updatedMappings = updatedMappings.filter(
          (row) =>
            !state.commitedLocationRows.some((temp) => temp.id === row.id)
        );
      }

      return {
        ...state,
        locationMappings: [...newMappings, ...updatedMappings],
        editedLocationRows: [],
        commitedLocationRows: [],
        isCreateLocationMapping: false,
      };
    }
    case "update-surgeon-mappings": {
      let updatedMappings = state.surgeonMappings.map((row) => {
        const change = action.payload.find((change) => change.id === row.id);
        return change ? { ...row, ...change } : row;
      });

      // add new mappings
      const newMappings = action.payload.filter(
        (change) => !state.surgeonMappings.some((row) => row.id === change.id)
      );

      // remove temp new mappings
      if (state.creatingSurgeonMapping) {
        updatedMappings = updatedMappings.filter(
          (row) => !state.commitedSurgeonRows.some((temp) => temp.id === row.id)
        );
      }

      return {
        ...state,
        surgeonMappings: [...newMappings, ...updatedMappings],
        editedSurgeonRows: [],
        commitedSurgeonRows: [],
        newSurgeonMappings: [],
        creatingSurgeonMapping: false,
      };
    }
    case "set-selected-location-mapping":
      return {
        ...state,
        selectedLocationMapping: action.payload,
      };
    case "set-selected-surgeon-mapping":
      return {
        ...state,
        selectedSurgeonMapping: action.payload,
      };
    case "set-selected-locations":
      return {
        ...state,
        selectedLocations: action.payload,
      };
    case "toggle-modal":
      return {
        ...state,
        isModalOpen: action.payload,
      };
    case "set-creating-surgeons":
      return {
        ...state,
        creatingSurgeonMapping: action.payload,
      };
    case "set-edited-surgeon-rows":
      return {
        ...state,
        editedSurgeonRows: action.payload,
      };
    case "set-edited-location-rows":
      return {
        ...state,
        editedLocationRows: action.payload,
      };
    case "set-commited-location-rows":
      return {
        ...state,
        commitedLocationRows: action.payload.id
          ? state.commitedLocationRows.some((r) => r.id === action.payload.id)
            ? state.commitedLocationRows.filter(
                (r) => r.id !== action.payload.id
              )
            : [...state.commitedLocationRows, action.payload]
          : action.payload,
      };
    case "set-is-create-location":
      return {
        ...state,
        isCreateLocationMapping: action.payload,
      };
    case "set-add-location-row":
      return {
        ...state,
        locationMappings: [action.payload, ...state.locationMappings],
      };
    case "cancel-add-location-rows":
      return {
        ...state,
        locationMappings: state.locationMappings.filter((row) => {
          return (
            !state.editedLocationRows.some((newRow) => newRow.id === row.id) &&
            !state.commitedLocationRows.some((newRow) => newRow.id === row.id)
          );
        }),
        isCreateLocationMapping: false,
      };
    case "set-commited-surgeon-rows":
      return {
        ...state,
        commitedSurgeonRows: action.payload.id
          ? state.commitedSurgeonRows.some((r) => r.id === action.payload.id)
            ? state.commitedSurgeonRows.filter(
                (r) => r.id !== action.payload.id
              )
            : [...state.commitedSurgeonRows, action.payload]
          : action.payload,
      };
    case "cancel-new-surgeons":
      return {
        ...state,
        newSurgeonMappings: [],
      };
    case "set-new-surgeons":
      return {
        ...state,
        newSurgeonMappings: action.payload,
      };
    case "set-surgeon-search":
      return {
        ...state,
        searchValue: action.payload,
        physician: "",
      };

    case "reset-selected-mappings":
      return {
        ...state,
        editedLocationRows: [],
        commitedLocationRows: [],
        selectedLocations: [],
        isCreateLocationMapping: false,
        editedSurgeonRows: [],
        commitedSurgeonRows: [],
        selectedSurgeonMappings: [],
        newSurgeonMappings: [],
        creatingSurgeonMapping: false,
      };
    case "error":
      console.log("error :: ", action.payload);
      return {
        ...state,
        errorMessage: action.payload,
        statusMessage: "",
      };
    default:
      return {
        ...state,
      };
  }
};

const toggleMapping = (dispatch) => (isLocation) => {
  dispatch({
    type: "toggle-mapping",
    payload: isLocation,
  });

  dispatch({
    type: "reset-filters",
  });

  dispatch({
    type: "reset-selected-mappings",
  });
};

const setLocationSearch = (dispatch) => (searchValue) => {
  dispatch({
    type: "set-location-search",
    payload: searchValue,
  });
};

const setPhysicianFilter = (dispatch) => (physician) => {
  dispatch({
    type: "set-physician-filter",
    payload: physician,
  });
};

const setLocationFilter = (dispatch) => (location) => {
  dispatch({
    type: "set-location-filter",
    payload: location,
  });
};

const clearFilters = (dispatch) => (location) => {
  dispatch({
    type: "reset-filters",
  });
};

const getLocations = (dispatch) => async () => {
  try {
    const response = await locationMappingsInstance.getLocationIdentifiers();
    if (response.status === 200 && response.data) {
      return dispatch({ type: "set-locations", payload: response.data });
    }
  } catch (error) {
    return dispatch({ type: "error", payload: "Could not get locations" });
  }
};

const getSurgeonMappings = (dispatch) => async (userId, cursor) => {
  try {
    const response = await surgeonMappingsInstance.getSurgeonMappings(
      userId || undefined,
      cursor || undefined
    );
    if (response.status === 200 && response.data && response.headers) {
      dispatch({
        type: "set-surgeon-mappings",
        payload: {
          surgeonMappings: response.data,
          cursor: response.headers["x-next-page-cursor"],
          prevCursor: cursor,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: "error",
      payload: "Could not get surgeon mappings",
    });
  }
};

const getSurgeonMappingsById = (dispatch) => async (id) => {
  try {
    const response = await surgeonMappingsInstance.getSurgeonMapping(id);

    if (response.status === 200 && response.data) {
      dispatch({
        type: "set-surgeon-mappings",
        payload: { surgeonMappings: response.data ? [response.data] : [] },
      });
    }
  } catch (error) {
    dispatch({
      type: "error",
      payload: "Could not get surgeon mapping by searched ID",
    });
  }
};

const setSelectedSurgeons = (dispatch) => (mapping) => {
  dispatch({ type: "set-selected-surgeon-mappings", payload: mapping });
};

const setSelectAllSurgeons = (dispatch) => (isSelectAll) => {
  dispatch({ type: "set-select-all-surgeon", payload: isSelectAll });
};

const getLocationMappingById = (dispatch) => async (id) => {
  try {
    const response = await locationMappingsInstance.getLocationMapping(id);
    if (response.status === 200 && response.data) {
      dispatch({
        type: "set-location-mappings",
        payload: { locationMappings: response.data ? [response.data] : [] },
      });
    }
  } catch (error) {
    dispatch({
      type: "error",
      payload: "Could not get location mapping",
    });
  }
};

const getLocationMappings =
  (dispatch) => async (userId, uniqueLocationId, cursor) => {
    try {
      const response = await locationMappingsInstance.getLocationMappings(
        userId || undefined,
        uniqueLocationId || undefined,
        cursor || undefined
      );
      if (response.status === 200 && response.data && response.headers) {
        dispatch({
          type: "set-location-mappings",
          payload: {
            locationMappings: response.data,
            cursor: response.headers["x-next-page-cursor"],
            prevCursor: cursor,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: "error",
        payload: "Could not get location mappings",
      });
    }
  };

const updateLocationMappings = (dispatch) => async (mappings) => {
  //api bulk edit/create location mapping
  try {
    const response = await locationMappingsInstance.updateLocationMappings(
      mappings
    );
    if (response.status === 200 && response.data) {
      dispatch({ type: "update-location-mappings", payload: response.data });
    }
  } catch (error) {
    dispatch({
      type: "error",
      payload: "Could not get update/create location mappings",
    });
  }
};

const updateSurgeonMappings = (dispatch) => async (mappings) => {
  try {
    const response = await surgeonMappingsInstance.updateSurgeonMappings(
      mappings
    );
    if (response.status === 200 && response.data) {
      dispatch({ type: "update-surgeon-mappings", payload: response.data });
    }
  } catch (error) {
    dispatch({
      type: "error",
      payload: "Could not update/create surgeon mappings",
    });
  }
};

const deleteLocationMapping =
  (dispatch) => async (mappingsList, selectedMappingIds) => {
    const updatedMappings = mappingsList.filter(
      (mapping) => !selectedMappingIds.some((loc) => loc.id === mapping.id)
    );

    try {
      const response = await locationMappingsInstance.deleteLocationMappings(
        selectedMappingIds
      );
      if (response.status === 204) {
        dispatch({
          type: "set-location-mappings",
          payload: { locationMappings: updatedMappings },
        });
      }
    } catch (error) {
      dispatch({
        type: "error",
        payload: "Could not delete location mappings",
      });
    }
  };

const deleteSurgeonMapping =
  (dispatch) => async (mappingsList, selectedMappingIds) => {
    const updatedMappings = mappingsList.filter(
      (mapping) =>
        !selectedMappingIds.some((surgeon) => surgeon.id === mapping.id)
    );
    try {
      const response = await surgeonMappingsInstance.deleteSurgeonMappings(
        selectedMappingIds
      );
      if (response.status === 204) {
        dispatch({
          type: "set-surgeon-mappings",
          payload: { surgeonMappings: updatedMappings },
        });
      }
    } catch (error) {
      dispatch({
        type: "error",
        payload: "Could not delete selected surgeon mappings",
      });
    }
  };

const setSelectedSurgeonMapping = (dispatch) => (mapping) => {
  dispatch({ type: "set-selected-surgeon-mapping", payload: mapping });
};

const setSelectedLocationMapping = (dispatch) => (mapping) => {
  dispatch({ type: "set-selected-location-mapping", payload: mapping });
};

const setSelectedLocations = (dispatch) => (selectedLocations) => {
  dispatch({ type: "set-selected-locations", payload: selectedLocations });
};

const setEditedSurgeonRows = (dispatch) => (editedRows) => {
  dispatch({ type: "set-edited-surgeon-rows", payload: editedRows });
};

const toggleModal = (dispatch) => (isModalOpen) => {
  dispatch({ type: "toggle-modal", payload: isModalOpen });
};

const setCreatingSurgeons = (dispatch) => (value) => {
  dispatch({ type: "set-creating-surgeons", payload: value });
};

const setEditedLocationRows = (dispatch) => (editedRows) => {
  dispatch({ type: "set-edited-location-rows", payload: editedRows });
};

const setCommitedLocationRows = (dispatch) => (commitedRow) => {
  dispatch({ type: "set-commited-location-rows", payload: commitedRow });
};

const setCreateLocation = (dispatch) => (isCreate) => {
  dispatch({ type: "set-is-create-location", payload: isCreate });
};

const setAddLocationRow = (dispatch) => (newRow) => {
  dispatch({ type: "set-add-location-row", payload: newRow });
};

const cancelAddLocationRows = (dispatch) => () => {
  dispatch({ type: "cancel-add-location-rows" });
};

const setCommitedSurgeonRows = (dispatch) => (commitedRow) => {
  dispatch({ type: "set-commited-surgeon-rows", payload: commitedRow });
};

const setNewSurgeons = (dispatch) => (mappings) => {
  dispatch({ type: "set-new-surgeons", payload: mappings });
};

const setSurgeonSearch = (dispatch) => (searchValue) => {
  dispatch({
    type: "set-surgeon-search",
    payload: searchValue,
  });
};

const resetErrorMessage = (dispatch) => (searchValue) => {
  dispatch({
    type: "error",
    payload: "",
  });
};

export const { Provider, Context } = createDataContext(
  mappingReducer,
  {
    toggleMapping,
    setLocationSearch,
    setPhysicianFilter,
    setLocationFilter,
    clearFilters,
    getSurgeonMappings,
    getSurgeonMappingsById,
    setSelectedSurgeons,
    setSelectAllSurgeons,
    getLocationMappingById,
    getLocationMappings,
    updateLocationMappings,
    deleteLocationMapping,
    setSelectedLocationMapping,
    setSelectedSurgeonMapping,
    deleteSurgeonMapping,
    setSelectedLocations,
    toggleModal,
    setCreatingSurgeons,
    setEditedLocationRows,
    setCommitedLocationRows,
    setCreateLocation,
    setAddLocationRow,
    cancelAddLocationRows,
    setCommitedSurgeonRows,
    setNewSurgeons,
    setEditedSurgeonRows,
    updateSurgeonMappings,
    setSurgeonSearch,
    getLocations,
    resetErrorMessage,
  },
  initialState
);
