import styled from "styled-components";
import React, { useContext, useEffect } from "react";
import { Context as MappingContext } from "../../context/MappingContext";
import Checkbox from "../Checkbox/Checkbox";
import CommitCheck from "../../assets/CommitCheck.svg";
import UncommitIcon from "../../assets/UncommitIcon.svg";
import EditIcon from "../../assets/EditIcon.svg";
import DeleteIcon from "../../assets/DeleteIcon.svg";
import AddIcon from "../../assets/AddIcon.svg";
import TableDropdown from "../TableDropdown/TableDropdown";
import moment from "moment";
import DocSperaButton from "../DocSperaButton/DocSperaButton";
import { isValidInput } from "../../libraries/helpers";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { SearchLocationIDs } from "../SearchLocationIDs/SearchLocationIDs";
// import toast from "react-hot-toast";

const LocationTableContainer = styled.div`
  height: 80vh;
  overflow: auto;
  border: 1px solid #ebebeb;
  border-radius: 10px;
  position: relative;
  margin: 15px;

  @media (max-width: 1230px) {
    height: 75vh;
  }
`;

const LocationTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  padding: 5px;
  height: 100%;
  table-layout: fixed;
  min-width: 1640px;

  thead {
    position: sticky;
    top: 0;
    z-index: 1;

    th {
      font-weight: bold;
      border-bottom: 2px solid #0096fa;

      border-right: 1px solid #ddd;
      &:last-child {
        border-right: none;
      }
    }
  }

  tr {
    display: grid;
    height: fit-content;
    grid-template-columns: 0.6fr 0.5fr 0.75fr 1.3fr 0.75fr 1.05fr 1.4fr 1fr 1fr 0.75fr;

    th,
    td {
      border-right: 1px solid #dee2e6;
      &:last-child {
        border-right: none;
      }

      &.multi-select {
        background: white;
        position: sticky;
        top: 0;
        left: 0;
        background: white;
        z-index: 0;
        width: 100%;

        &.disabled {
          pointer-events: none;
          border-color: rgba(222, 226, 230, 0.5);
          border-bottom-color: #0096fa;
        }
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid #ddd;

      &.commit-row {
        border: 1px solid #0096fa;
      }

      &.disable-row {
        pointer-events: none;
        opacity: 50%;
      }
    }
  }
`;

export const TableHead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 10;
  border-bottom: 2px solid #ddd;
`;

export const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background: white;
  font-weight: bold;

  &.actions {
    display: flex;
    justify-content: space-between;

    .multi-delete {
      border: 1px solid #ff0000;
      border-radius: 3px;
      padding: 2px !important;
      cursor: pointer;

      .icon {
        background-color: #ff0000 !important;
        padding: 2px !important;
      }

      &:hover {
        background-color: #ff0000;
        color: #fff;
      }
    }

    .multi-create {
      border: 1px solid #0096fa;
      border-radius: 3px;
      padding: 2px !important;
      cursor: pointer;

      .icon {
        background-color: #0096fa !important;
        padding: 2px !important;
      }

      &:hover {
        background-color: #0096fa;
        color: #fff;
      }
    }

    &.disabled {
      > div,
      .multi-delete,
      .multi-create {
        opacity: 35%;
      }
    }
  }
`;

export const TableCell = styled.td`
  padding: 10px;
  text-align: left;
  font-size: 14px;

  span {
    overflow-wrap: anywhere;
  }

  input {
    border: none;
    background-color: #f3f3f3;
    border-radius: 2px;
    color: #0096fa;
    width: 100%;
    padding: 0 5px;

    &.error {
      border: 1px solid red;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .status {
    &.ACTIVE {
      color: #52e100;
    }

    &.INACTIVE {
      color: #ff0000;
    }
  }

  &.actions {
    display: flex;
    justify-content: space-between;

    .item {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
      text-align: left;
    }
  }
`;

export const CheckboxCell = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 100;
  background-color: #f3f3f3;
  padding: 0 5px;
`;

export const LocationPillContainer = styled.div`
  display: flex;
  gap: 5px;
  width: fit-content;
  flex-wrap: wrap;
`;

export const LocationPill = styled.span`
  font-size: 0.75rem;
  border: 0.5px solid #d8d8d8;
  background-color: #f8f8f8;
  border-radius: 50px;

  padding: 3px 8px;
`;

export const locationHeaders = [
  { name: "ID", key: "id", isRequired: false },
  { name: "User ID", key: "user_id", type: "integer", isRequired: true },
  { name: "Timestamp", key: "timestamp", type: "timestamp", isRequired: false },
  { name: "Status", key: "status", type: "checkbox", isRequired: false },
  {
    name: "Unique Location ID",
    key: "unique_location_id",
    type: "integer",
    isRequired: true,
  },
  {
    name: "Location Name",
    key: "location_name",
    type: "string",
    isRequired: true,
  },
  {
    name: "Location IDs",
    key: "location_ids",
    type: "string-list",
    isRequired: false,
  },
  {
    name: "Schedule Type",
    key: "surgery_schedule_type",
    type: "dropdown",
    options: [
      { value: null, name: "None" },
      { value: "weekly", name: "Weekly" },
      { value: "monthly", name: "Monthly" },
      { value: "case", name: "Case" },
      { value: "case_details", name: "Case Details" },
      { value: "setup", name: "Setup" },
      { value: "location_name", name: "Location Name" },
    ],
    isRequired: false,
  },
  {
    name: "Surgery Days",
    key: "surgery_days",
    type: "days",
    isRequired: false,
  },
];

function LocationMappings() {
  const {
    state: mappingState,
    getLocationMappingById,
    getLocationMappings,
    setSelectedLocationMapping,
    setSelectedLocations,
    toggleModal,
    setEditedLocationRows,
    setCommitedLocationRows,
    setCreateLocation,
    setAddLocationRow,
  } = useContext(MappingContext);

  useEffect(() => {
    if (mappingState.searchValue) {
      //delay calling getLocationMappingById search
      const timeout = setTimeout(() => {
        getLocationMappingById(mappingState.searchValue);
      }, 500);

      return () => clearTimeout(timeout);
    } else {
      getLocationMappings(mappingState.physician, mappingState.location);
    }
  }, [mappingState.searchValue, mappingState.physician, mappingState.location]);

  const handleChange = (rowId, value, key) => {
    const updatedRows = mappingState.editedLocationRows.map((row) =>
      row.id === rowId ? { ...row, [key]: value } : row
    );
    setEditedLocationRows(updatedRows);
  };

  const handleEdit = (row) => {
    setEditedLocationRows(
      mappingState.editedLocationRows.some((r) => r.id === row.id)
        ? mappingState.editedLocationRows
        : [...mappingState.editedLocationRows, { ...row }]
    );
  };

  const handleCommit = (row) => {
    let editedRow = mappingState.editedLocationRows.find(
      (r) => r.id === row.id
    );
    setCommitedLocationRows(editedRow);
  };

  const handleDelete = (row) => {
    setSelectedLocationMapping(row);
    toggleModal(true);
  };

  const handleMultiSelect = () => {
    setSelectedLocations(
      mappingState.selectedLocations.length > 0
        ? []
        : mappingState.locationMappings.map((mapping) => ({ id: mapping.id }))
    );
  };

  const handleSingleSelect = (row) => {
    setSelectedLocations(
      !mappingState.selectedLocations.some((loc) => loc.id === row.id)
        ? [...mappingState.selectedLocations, { id: row.id }]
        : mappingState.selectedLocations.filter((loc) => loc.id !== row.id)
    );
  };

  const handleAddNewMapping = () => {
    const newMapping = {
      id: Date.now(),
      user_id: 0,
      status: "INACTIVE",
      unique_location_id: 0,
      location_name: "",
      location_ids: "",
      surgery_schedule_type: null,
      surgery_days: "",
    };
    setCreateLocation(true);
    setEditedLocationRows([newMapping, ...mappingState.editedLocationRows]);
    setAddLocationRow(newMapping);
  };

  const handleLoadMoreLocationMappings = () => {
    getLocationMappings(
      mappingState.physician,
      mappingState.location,
      mappingState.locationsCursor
    );
  };

  const locationsObserverRef = useIntersectionObserver({
    callback: handleLoadMoreLocationMappings,
    cursor: mappingState.locationsCursor,
  });

  return (
    <LocationTableContainer>
      <LocationTable>
        <thead>
          <tr>
            <TableHeader
              className={`actions multi-select ${
                mappingState.editedLocationRows.length > 0 &&
                !mappingState.isCreateLocationMapping
                  ? "disabled"
                  : ""
              }`}
            >
              <Checkbox
                multiSelect={mappingState.selectedLocations.length > 1}
                toggled={
                  mappingState.locationMappings.length !== 0 &&
                  mappingState.selectedLocations.length ===
                    mappingState.locationMappings.length
                }
                clickedFunction={handleMultiSelect}
                disabled={mappingState.editedLocationRows.length > 0}
              />
              {mappingState.selectedLocations.length > 0 ? (
                <DocSperaButton
                  className="multi-delete secondary"
                  height="14px"
                  width="14px"
                  icon={DeleteIcon}
                  onPress={() => handleDelete()}
                />
              ) : (
                <DocSperaButton
                  className={`multi-create secondary
                    ${
                      mappingState.editedLocationRows.length > 0 &&
                      !mappingState.isCreateLocationMapping
                        ? "disabled"
                        : ""
                    }`}
                  height="14px"
                  width="14px"
                  icon={AddIcon}
                  onPress={() => handleAddNewMapping()}
                />
              )}
            </TableHeader>
            {locationHeaders.map((header, index) => (
              <TableHeader key={index}>{header.name}</TableHeader>
            ))}
          </tr>
        </thead>
        <tbody>
          {mappingState.locationMappings?.map((row, rowIndex) => {
            // console.log(row, mappingState.editedLocationRows);
            return (
              <tr
                key={rowIndex}
                className={`${
                  mappingState.commitedLocationRows.some((r) => r.id === row.id)
                    ? "commit-row"
                    : mappingState.isCreateLocationMapping &&
                      !mappingState.editedLocationRows.some(
                        (r) => r.id === row.id
                      )
                    ? "disable-row"
                    : ""
                } `}
              >
                <TableCell className="actions multi-select">
                  {(!mappingState.editedLocationRows.length ||
                    (mappingState.isCreateLocationMapping &&
                      !mappingState.editedLocationRows.some(
                        (r) => r.id === row.id
                      ))) && (
                    <Checkbox
                      toggled={mappingState.selectedLocations.some(
                        (loc) => loc.id === row.id
                      )}
                      clickedFunction={() => handleSingleSelect(row)}
                    />
                  )}
                  {mappingState.editedLocationRows.some(
                    (r) => r.id === row.id
                  ) ? (
                    !mappingState.commitedLocationRows.some(
                      (r) => r.id === row.id
                    ) ? (
                      <div className="item" onClick={() => handleCommit(row)}>
                        <img src={CommitCheck} alt="commit" />
                        <span style={{ color: "#002855" }}>Commit</span>
                      </div>
                    ) : (
                      <div className="item" onClick={() => handleCommit(row)}>
                        <img src={UncommitIcon} alt="uncommit" />
                        <span style={{ color: "#FF0000" }}>Uncommit</span>
                      </div>
                    )
                  ) : (
                    <div className="item" onClick={() => handleEdit(row)}>
                      <img src={EditIcon} alt="edit" height={18} width={18} />
                      {!!mappingState.editedLocationRows.length &&
                        !mappingState.isCreateLocationMapping && (
                          <span style={{ color: "#0096FA" }}>Edit</span>
                        )}
                    </div>
                  )}
                </TableCell>
                {locationHeaders.map((header, colIndex) => {
                  return (
                    <TableCell key={colIndex}>
                      {mappingState.editedLocationRows.some(
                        (r) => r.id === row.id
                      ) ? (
                        header.type === "checkbox" ? (
                          <CheckboxCell>
                            <Checkbox
                              size="14px"
                              toggled={
                                mappingState.editedLocationRows.find(
                                  (r) => r.id === row.id
                                )[header.key] === "ACTIVE"
                              }
                              clickedFunction={() => {
                                const newStatus =
                                  mappingState.editedLocationRows.find(
                                    (r) => r.id === row.id
                                  )[header.key] === "ACTIVE"
                                    ? "INACTIVE"
                                    : "ACTIVE";
                                handleChange(row.id, newStatus, header.key);
                              }}
                              disabled={mappingState.commitedLocationRows.some(
                                (r) => r.id === row.id
                              )}
                            />{" "}
                            Is Active?
                          </CheckboxCell>
                        ) : header.type === "dropdown" ? (
                          <TableDropdown
                            placeholder={
                              mappingState.editedLocationRows.find(
                                (r) => r.id === row.id
                              )[header.key]
                            }
                            options={header.options}
                            onSelect={(value) =>
                              handleChange(row.id, value, header.key)
                            }
                            disabled={mappingState.commitedLocationRows.some(
                              (r) => r.id === row.id
                            )}
                          />
                        ) : header.type === "string-list" ? (
                          <SearchLocationIDs
                            rowId={row.id}
                            locationIDs={
                              mappingState.editedLocationRows.find(
                                (r) => r.id === row.id
                              )[header.key]
                            }
                          />
                        ) : (
                          <input
                            className={`${
                              isValidInput(
                                row,
                                header.type,
                                header.key,
                                mappingState.editedLocationRows
                              )
                                ? ""
                                : "error"
                            }`}
                            type={header.type === "integer" ? "number" : "text"}
                            value={
                              mappingState.isCreateLocationMapping &&
                              header.key === "id"
                                ? ""
                                : header.key === "timestamp" && row[header.key]
                                ? `${moment
                                    .utc(row[header.key])
                                    .local()
                                    .format("MMM DD, YYYY [at] h:mm A")}`
                                : mappingState.editedLocationRows.find(
                                    (r) => r.id === row.id
                                  )[header.key] || ""
                            }
                            onChange={(e) =>
                              handleChange(
                                row.id,
                                header.type === "integer"
                                  ? parseInt(e.target.value)
                                  : e.target.value,
                                header.key
                              )
                            }
                            disabled={
                              header.key === "id" ||
                              header.key === "timestamp" ||
                              mappingState.commitedLocationRows.some(
                                (r) => r.id === row.id
                              )
                            }
                          />
                        )
                      ) : header.type === "string-list" ? (
                        row[header.key] && (
                          <LocationPillContainer>
                            {row[header.key].split(",").map((locationId) => (
                              <LocationPill key={locationId}>
                                {locationId}
                              </LocationPill>
                            ))}
                          </LocationPillContainer>
                        )
                      ) : (
                        <span
                          className={
                            header.type === "checkbox"
                              ? `${header.key} ${row[header.key]}`
                              : header.key
                          }
                        >
                          {header.type === "timestamp"
                            ? `${moment
                                .utc(row[header.key])
                                .local()
                                .format("MMM DD, YYYY [at] h:mm A")}`
                            : header.type === "dropdown"
                            ? row[header.key] &&
                              row[header.key]
                                .replace(/_/g, " ")
                                .replace(/\b\w/g, (char) => char.toUpperCase())
                            : row[header.key]}{" "}
                        </span>
                      )}
                    </TableCell>
                  );
                })}
              </tr>
            );
          })}
          <tr
            ref={locationsObserverRef}
            style={{
              visibility: "hidden",
              height: 0,
              padding: 0,
              minHeight: 0,
            }}
          />
        </tbody>
      </LocationTable>
    </LocationTableContainer>
  );
}

export default LocationMappings;
