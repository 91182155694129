import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import NavBar from "../../components/NavBar/NavBar";
import Calendar from "../Calendar/Calendar";
import { Provider as AuthProvider } from "../../context/AuthContext";
import { Provider as GlobaleProvider } from "../../context/GlobalContext";
import { Provider as EventProvider } from "../../context/EventContext";
import { Provider as MappingProvider } from "../../context/MappingContext";
import { Toaster } from "react-hot-toast";
import Mappings from "../Mappings/Mappings";

function App() {
  return (
    <AuthProvider>
      <GlobaleProvider>
        <EventProvider>
          <MappingProvider>
            <Toaster position="top-right" />
            <div className="App">
              <NavBar />
              <Routes>
                <Route path="/" element={<Calendar />} />
                <Route
                  path="/mappings"
                  element={<Navigate to="/mappings/locations" replace />}
                />

                <Route
                  path="/mappings/locations"
                  element={<Mappings isLocation={true} />}
                />
                <Route
                  path="/mappings/surgeons"
                  element={<Mappings isLocation={false} />}
                />
              </Routes>
            </div>
          </MappingProvider>
        </EventProvider>
      </GlobaleProvider>
    </AuthProvider>
  );
}

export default App;
