const JOLTER_URL = process.env.REACT_APP_JOLTER_URL || "";
const ABS_BASE_URL = process.env.REACT_APP_ABS_BASE_URL || "";
const ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL || "";

const config = {
  ABS_BASE_URL: ABS_BASE_URL,
  JOLTER_URL: JOLTER_URL,
  ADMIN_BASE_URL: ADMIN_BASE_URL,
};

export default config;
