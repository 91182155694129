import { useContext } from "react";
import styled from "styled-components";
import { Context as MappingContext } from "../../context/MappingContext";
import DocSperaButton from "../DocSperaButton/DocSperaButton";

const ModalContent = styled.div`
  text-align: left;
  p {
    color: #e23118;
    span {
      font-weight: bold;
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

function DeleteMappingModal() {
  const {
    state: mappingState,
    deleteLocationMapping,
    setSelectedLocations,
    toggleModal,
    deleteSurgeonMapping,
    setSelectedSurgeons,
    setSelectAllSurgeons,
    getLocations,
  } = useContext(MappingContext);

  const handleDeleteMapping = () => {
    if (mappingState.isLocation) {
      if (mappingState.selectedLocationMapping) {
        //single delete
        deleteLocationMapping(mappingState.locationMappings, [
          {
            id: mappingState.selectedLocationMapping.id,
          },
        ]);
      } else {
        //multi-delete
        deleteLocationMapping(
          mappingState.locationMappings,
          mappingState.selectedLocations
        );
      }
      // get updated locations list
      getLocations();
      setSelectedLocations([]);
    } else {
      if (mappingState.selectedSurgeonMapping) {
        // single delete
        deleteSurgeonMapping(mappingState.surgeonMappings, [
          { id: mappingState.selectedSurgeonMapping.id },
        ]);
      } else {
        const separatedIds = mappingState.selectedSurgeonMappings.map(
          (mapping) => ({ id: mapping })
        );
        deleteSurgeonMapping(mappingState.surgeonMappings, separatedIds);
      }
      setSelectAllSurgeons(false);
      setSelectedSurgeons([]);
    }
    toggleModal(false);
  };

  const handleCancel = () => {
    if (mappingState.isLocation) {
      setSelectedLocations([]);
    }
    toggleModal(false);
  };

  return (
    <ModalContent>
      {mappingState.isLocation ? (
        mappingState.selectedLocations.length === 1 ? (
          <p>
            You are about to delete location mapping ID{" "}
            <span>{mappingState.selectedLocations[0].id}</span>. This cannot be
            undone, are you sure you want to continue?
          </p>
        ) : (
          <p>
            You are about to delete multiple location mappings. This cannot be
            undone, are you sure you want to continue?
          </p>
        )
      ) : mappingState.selectedSurgeonMappings.length === 1 ? (
        <p>
          You are about to delete surgeon mapping to{" "}
          <span>{mappingState.selectedSurgeonMappings[0]}</span>. This cannot be
          undone, are you sure you want to continue?
        </p>
      ) : (
        <p>
          You are about to delete multiple surgeon mappings. This cannot be
          undone, are you sure you want to continue?
        </p>
      )}
      <ModalFooter>
        <DocSperaButton
          className="secondary red"
          label="Delete"
          onPress={handleDeleteMapping}
        />
        <DocSperaButton
          className="primary"
          label="Cancel"
          onPress={handleCancel}
        />
      </ModalFooter>
    </ModalContent>
  );
}

export default DeleteMappingModal;
