import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { Context as EventContext } from "../../context/EventContext";
import { Context as MappingContext } from "../../context/MappingContext";

import SearchIcon from "../../assets/BlueSearchIcon.svg";
import NewIDIcon from "../../assets/AddNewID.svg";
import CommitPill from "../../assets/CommitPill.svg";
import RemovePill from "../../assets/RemovePill.svg";
import CloseIcon from "../../assets/Close.svg";

const StyledSearchIDs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
  position: relative;
  align-items: flex-start;

  .pill-container {
    padding: 5px;
    background-color: #f3f3f3;
    border-radius: 2px;
    display: flex;
    gap: 5px;
    flex-wrap: wrap;

    .add-new-id {
      position: relative;
      cursor: pointer;
    }
  }
`;

const CommittedPillContainer = styled.div`
  position: relative;

  .pill {
    font-size: 0.75rem;
    border: 0.5px solid #d8d8d8;
    background-color: #f8f8f8;
    border-radius: 50px;
    width: fit-content;
    padding: 3px 8px;
  }

  .remove {
    position: absolute;
    top: -3px;
    right: -3px;
    background-color: #ff0000;
    padding: 3px;
    border-radius: 10px;
    cursor: pointer;
    height: 14px;

    img {
      filter: brightness(0) invert(1);
      height: 100%;
      width: auto;
      object-fit: cover;
      vertical-align: top;
    }
  }
`;

const NewPill = styled.div`
  display: flex;
  gap: 1px;
  font-size: 0.75rem;
  border: 0.5px dashed #d8d8d8;
  background-color: #f8f8f8;
  border-radius: 50px;
  width: fit-content;
  padding: 0 3px;
  align-items: center;

  input {
    background-color: #f8f8f8 !important;
    max-width: 50px;

    &:focus {
      outline: none;
    }
  }

  img {
    cursor: pointer;
    max-height: 10px;
  }

  span {
    margin-right: 5px;
  }
`;

const SearchContainer = styled.div`
  min-height: 50px;
  padding: 10px;
  background: white;
  border: 1px solid #0096fa;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 10;

  .search-row {
    display: flex;
    gap: 5px;
    align-items: center;

    img {
      filter: brightness(0);
      height: 13px;
      width: auto;
      cursor: pointer;
    }
  }

  .found-location {
    margin: 10px 0 0 0;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;

    span {
      color: #0096fa;
      font-style: italic;
    }
  }
`;

export function SearchLocationIDs({ rowId, locationIDs }) {
  const { getCase } = useContext(EventContext);
  const { state: mappingState, setEditedLocationRows } =
    useContext(MappingContext);
  const [isOpen, setIsOpen] = useState(false);
  const [searchEventId, setSearchEventId] = useState("");
  const [foundEventLocation, setFoundEventLocation] = useState(null);
  const [newLocation, setNewLocation] = useState(null);
  const locationIDsArray = locationIDs
    ? locationIDs.split(",").map((id) => id.trim())
    : [];

  useEffect(() => {
    if (searchEventId !== "") {
      const timeout = setTimeout(async () => {
        const response = await getCase(searchEventId);
        if (
          response &&
          response.event &&
          response.event.length > 0 &&
          response.event[0].location
        ) {
          setFoundEventLocation(response.event[0].location);
        }
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [searchEventId]);

  const handleAddLocation = () => {
    setIsOpen(true);
  };

  const addLocationID = (locationID) => {
    const updatedRows = mappingState.editedLocationRows.map((editedRow) =>
      editedRow.id === rowId
        ? {
            ...editedRow,
            location_ids: (() => {
              const currentIds = editedRow.location_ids
                ? editedRow.location_ids.split(",").map((id) => id.trim())
                : [];
              if (!currentIds.includes(locationID.toString())) {
                currentIds.push(locationID);
              }
              return currentIds.join(",");
            })(),
          }
        : editedRow
    );
    setEditedLocationRows(updatedRows);
    setIsOpen(false);
    setFoundEventLocation(null);
    setSearchEventId("");
  };

  const removeLocationID = (locationID) => {
    const updatedRows = mappingState.editedLocationRows.map((editedRow) =>
      editedRow.id === rowId
        ? {
            ...editedRow,
            location_ids: (() => {
              const currentIds = editedRow.location_ids
                ? editedRow.location_ids.split(",").map((id) => id.trim())
                : [];
              const updatedIds = currentIds.filter(
                (id) => id !== locationID.toString()
              );
              return updatedIds.join(",");
            })(),
          }
        : editedRow
    );
    setEditedLocationRows(updatedRows);
  };

  return (
    <StyledSearchIDs>
      <img
        style={{ marginTop: "5px", cursor: "pointer" }}
        src={SearchIcon}
        alt="search-ids"
        onClick={handleAddLocation}
      />
      <div className="pill-container">
        {locationIDsArray.map((id, idx) => (
          <CommittedPillContainer key={`id-${idx}`}>
            <div className="pill">{id}</div>
            <div className="remove" onClick={() => removeLocationID(id)}>
              <img src={RemovePill} alt="remove-selected-id" />
            </div>
          </CommittedPillContainer>
        ))}
        {newLocation !== null && (
          <NewPill>
            <img
              className="remove"
              src={RemovePill}
              alt="remove-selected-id"
              onClick={() => setNewLocation(null)}
            />
            <input
              type="number"
              placeholder="ID"
              value={newLocation}
              onChange={(e) => setNewLocation(e.target.value)}
            />
            <img
              src={CommitPill}
              alt="commit-custom-id"
              onClick={() => {
                addLocationID(newLocation);
                setNewLocation(null);
              }}
            />
          </NewPill>
        )}
        <img
          className="add-new-id"
          src={NewIDIcon}
          alt="add-new-id"
          onClick={() => setNewLocation("")}
        />
        {isOpen && (
          <SearchContainer>
            <div className="search-row">
              <img
                src={CloseIcon}
                alt="close-search-container"
                onClick={() => setIsOpen(false)}
              />
              <input
                type="text"
                placeholder="Search Event ID"
                value={searchEventId}
                onChange={(e) => setSearchEventId(e.target.value)}
              />
            </div>
            <div>
              {foundEventLocation && (
                <p
                  onClick={() => addLocationID(foundEventLocation.ahaid)}
                  className="found-location"
                >
                  {foundEventLocation.ahaid}
                  <span> - {foundEventLocation.name}</span>
                </p>
              )}
            </div>
          </SearchContainer>
        )}
      </div>
    </StyledSearchIDs>
  );
}
