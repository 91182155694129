import React from "react";
import styled from "styled-components";
import DocSperaArrow from "../../assets/arrow.svg";
// import Close from "../../assets/Close.svg";
import { useDropdown } from "./useDropdown";
// import SearchIcon from "../../assets/SearchIcon.svg";
import useInfiniteScroll from "react-infinite-scroll-hook";
import CalendarPicker from "../CalendarPicker/CalendarPicker";
import moment from "moment";
// import Checkbox from "../CheckBox/CheckBox";

const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${(props) => (props.$minWidth ? props.$minWidth : "180px")};
  font-family: Helvetica;
  height: fit-content;
  max-width: ${(props) => (props.$maxWidth ? props.$maxWidth : "400px")};

  .title {
    font-size: 12px;
    text-align: left;
    margin-bottom: 5px;
  }

  .dropdown-box {
    background-color: #fff;
    border: 1px solid;
    border-color: ${(props) =>
      props.$disabled ? "#9F9F9F" : props.$error ? "#E23118" : "#0096FA"};
    border-radius: 3px;
    position: relative;
    // cursor: ${(props) => (props.$isView ? "not-allowed" : "pointer")};
  }

  .container {
    height: max-content;
    display: flex;
    gap: 5px;
    width: 100%;
    align-items: center;
    margin-left: 5px;
  }

  input {
    background-color: white;
    padding: 10px;
    border: none;
    border-radius: 3px;
    color: black;
    font-size: 16px;
    outline: none;
    width: 100%;
    line-height: 21px;
  }

  .dropdown-row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  .option {
    min-height: 31px;
    font-size: 16px;
    padding: 5px 10px;
    color: ${(props) => (props.$disabled ? "#9F9F9F" : "#000")};
    visibility: ${(props) =>
      props.$isOpened && !props.$isWeek ? "hidden" : "visible"};
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .arrow {
    height: 15px;
    margin-right: 15px;

    &.up {
      transform: rotate(-90deg);
    }
    &.down {
      transform: rotate(90deg);
    }
  }

  ul {
    font-size: 16px;
    max-height: 160px;
    overflow-y: auto;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      text-align: left;
      padding: 2px 10px;
      cursor: pointer;

      &:hover {
        background-color: #b1e0ff7a;
      }

      &.selected {
        background-color: #b1e0ff7a;
      }

      &.disabled-option {
        pointer-events: none;
        color: #9f9f9f;
      }
    }
  }

  .error {
    font-size: 16px;
    margin-top: 1.5px;
    color: #e23118;
  }

  .options {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    position: absolute;
    background: white;
    min-width: ${(props) => (props.$minWidth ? props.$minWidth : "180px")};
    border: 1px solid #0096fa;
    right: -1px;
    top: 24px;
    border-radius: 3px;
    padding-bottom: 10px;
    background-color: white;
    z-index: 15;
    border-top: none;
    border-top-left-radius: revert;
    border-top-right-radius: revert;
    border-color: ${(props) =>
      props.$disabled ? "#9F9F9F" : props.$error ? "#E23118" : "#0096FA"};
    pointer-events: ${(props) => props.$disabled && "none"};
    width: 100.4%;
  }
`;

export default function DocSperaDropdown({
  name,
  dropdownTitle,
  selectedOption,
  optionLists = ["Yes", "No", "N/A"],
  disabled = false,
  grabbedValue = undefined,
  viewing = false,
  data = undefined,
  optionSelectHandler,
  minWidth,
  maxWidth,
  styles,
  showViewOption,
  // searchingFunction,
  infiniteScroll,
  customToggle,
  selectedKeys,
  handleClear,
}) {
  const {
    isToggled,
    hasError,
    IsOpened,
    SetOption,
    options,
    viewOption,
    setIsToggled,
    setViewOption,
    // searchedOptions,
    // setSearchedOptions,
  } = useDropdown({
    selectedOption,
    disabled,
    optionLists,
    grabbedValue,
    data,
    customToggle,
  });

  // Pass a prop that is technically the default value but it is an enteredValue
  // The Hook currently tests if the default value returns, so if we take in a new enteredvalue,
  // value can be passed as '''in

  const formatSelectedWeek = selectedOption
    ? moment(selectedOption, "YYYYMMDDHHmmss").startOf("week").format("MMM") !==
      moment(selectedOption, "YYYYMMDDHHmmss").endOf("week").format("MMM")
      ? `${moment(selectedOption, "YYYYMMDDHHmmss").format("MMM D")} - ${moment(
          selectedOption,
          "YYYYMMDDHHmmss"
        )
          .endOf("week")
          .subtract(1, "days")
          .format("MMM D, YYYY")}`
      : `${moment(selectedOption, "YYYYMMDDHHmmss").format("MMM D")} - ${moment(
          selectedOption,
          "YYYYMMDDHHmmss"
        )
          .endOf("week")
          .subtract(1, "days")
          .format("D, YYYY")}`
    : "All";

  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading: infiniteScroll?.isLoadingMore,
    hasNextPage: infiniteScroll?.hasMore,
    onLoadMore: infiniteScroll?.handleLoadMore,
  });

  return (
    <Dropdown
      $disabled={disabled || false}
      $error={hasError}
      $isOpened={isToggled}
      $isView={viewing}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      $isWeek={name === "week"}
      style={{ ...styles }}
    >
      {dropdownTitle && <div className="title">{dropdownTitle}</div>}
      <div className="dropdown-box">
        <div
          className="dropdown-row"
          onClick={!viewing ? IsOpened : undefined}
          onKeyDown={!viewing ? IsOpened : undefined}
        >
          {name === "week" ? (
            <div className="option">{formatSelectedWeek}</div>
          ) : showViewOption ? (
            <div className="option">{viewOption}</div>
          ) : (
            <div className="option">{selectedOption}</div>
          )}

          {!viewing && (
            <img
              className={`arrow ${isToggled ? "up" : "down"}`}
              src={DocSperaArrow}
              alt="arrow"
            />
          )}
        </div>
        {isToggled &&
          (name === "week" ? (
            <div
              style={{
                display: isToggled ? "block" : "none",
                position: "absolute",
              }}
            >
              <CalendarPicker
                id={`schedule-week`}
                name={"week"}
                selectBy={"week"}
                value={
                  selectedOption
                    ? moment(selectedOption, "YYYYMMDDHHmmss").format(
                        "YYYYMMDD"
                      )
                    : null
                }
                isOpen={isToggled}
                setIsOpen={setIsToggled}
                handleSelect={optionSelectHandler}
                handleClear={handleClear}
              />
            </div>
          ) : (
            <div className="options">
              <ul ref={rootRef}>
                {options.length > 0 &&
                  options.map((option) => {
                    return (
                      <li
                        key={`opt-${option.value || option.name || option}`}
                        className={`${
                          selectedKeys?.some((key) => key === option.value)
                            ? "disabled-option "
                            : ""
                        }
                          ${
                            selectedOption === option.value ||
                            selectedOption === option.name ||
                            selectedOption === option
                              ? "selected"
                              : ""
                          }`}
                        onClick={
                          optionSelectHandler
                            ? () => {
                                optionSelectHandler(
                                  name,
                                  option.value || option.name || option,
                                  data
                                );
                                setViewOption(option.name || option);
                                setIsToggled(false);
                              }
                            : () => SetOption(name, option)
                        }
                        role="presentation"
                      >
                        {option.name || option}
                      </li>
                    );
                  })}
                {(infiniteScroll?.isLoadingMore || infiniteScroll?.hasMore) && (
                  <li ref={infiniteRef}>Loading More...</li>
                )}
              </ul>
            </div>
          ))}
      </div>
      {hasError && <div className="error">You must select an option</div>}
    </Dropdown>
  );
}
