import axios from "axios";
import { getCookie } from "../helpers";
import config from "../config";
import { LocationMappingsApiFactory } from "docspera-admin-apis-axios-lib/api";

console.log(config);

const instance = axios.create({
  baseURL: "/",
  withCredentials: true, // todo: make this configurable
  headers: { "Content-Type": "application/json;charset=utf-8" },
});

const requestHandler = (request) => {
  request.headers.Authorization = `Bearer ${getCookie("docsperajwt")}`;
  return request;
};

instance.interceptors.request.use((request) => requestHandler(request));

const locationMappingsInstance = LocationMappingsApiFactory({}, "/", instance);

export default locationMappingsInstance;
